.chart-container{
    /* background-color:red; */
    height:80vh;
    width:100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.chart-divider{
    width:0.8vw;
}

.chart-comp-panel{
    flex:1;
    /* background-color: rgb(198, 168, 128); */
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 6px 12px rgb(164, 164, 164);
    padding:2%;
}

.chart-comp-body{
    height:100%;
}

.chart-graph-container{
    flex:4.6;
    border-radius: 10px;
    /* background-color: rgb(141, 76, 76); */
    border-radius: 0 0 10px 10px;
}

.chart-graph-controls{
    height:fit-content;
    margin-bottom: 1.75%;
    /* background-color: rgb(90, 90, 22); */
    background-color: white;
    border-radius: 10px 10px 0 0;
    padding: 0.5%;
    display: flex;
    flex-direction: row;
}

.chart-graph-body{
    height:88%;
    /* background-color: rgb(255, 255, 255); */
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: row;
}

.chart-graph-graph{
    flex:6.25;
    /* background-color: aqua; */
    border-radius: 0 0 0 10px;
}

.chart-graph-legend{
    flex:1;
    /* background-color: rgb(109, 154, 154); */
    border-radius: 0 0 10px 0;
    padding: 1%;
}

.chart-legend-element span{
    font-size: 0.85em;
    margin: 0;
    color:rgb(78,79,83);
    list-style-type: disc;
}